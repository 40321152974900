import {
  init,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { getCurrentEnvironment } from "~/utils/GetCurrentEnvironment";

const currentDomain = window.location.hostname;
const currentEnvironment = getCurrentEnvironment(currentDomain);

init({
  dsn: "https://10240b0dae977315a68699157d6802a9@o4507570388271104.ingest.us.sentry.io/4507570401640448",
  environment: currentEnvironment,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
