import { Environments, Environment } from "~/types";

export function getCurrentEnvironment(currentDomain: string): Environment {
  switch (currentDomain) {
    case "fjob-kyujin.com":
      return Environments.PRODUCTION;
    case "localhost":
      return Environments.LOCAL;
    default:
      return Environments.PREVIEW;
  }
}
